import React from 'react';



const NotFound =()=>{

    return(
        <div> 404 Page</div>
    )
}
export default NotFound;